import "core-js/modules/es.array.map.js";

var _import = require('./_import'); // 获取组件的方法


export var asyncRouterHandle = function asyncRouterHandle(asyncRouter) {
  asyncRouter.map(function (item) {
    if (item.component) {
      item.component = _import(item.component);
    } else {
      delete item['component'];
    }

    if (item.children) {
      asyncRouterHandle(item.children);
    }
  });
};