import "/Users/adway/Documents/agora.io/ad-manage-web/node_modules/core-js/modules/es.array.iterator.js";
import "/Users/adway/Documents/agora.io/ad-manage-web/node_modules/core-js/modules/es.promise.js";
import "/Users/adway/Documents/agora.io/ad-manage-web/node_modules/core-js/modules/es.object.assign.js";
import "/Users/adway/Documents/agora.io/ad-manage-web/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import App from './App.vue'; // 引入gin-vue-admin前端初始化相关内容

import './core/gin-vue-admin'; // 引入封装的router

import router from '@/router/index';
import '@/permission';
import { store } from '@/store';
Vue.config.productionTip = false;
import { auth } from '@/directive/auth'; // 按钮权限指令

auth(Vue);
export default new Vue({
  render: function render(h) {
    return h(App);
  },
  router: router,
  store: store
}).$mount('#app');