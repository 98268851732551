import service from '@/utils/request'; // @Summary 用户登录 获取动态路由
// @Produce  application/json
// @Param 可以什么都不填 调一下即可
// @Router /menu/getMenu [post]

export var asyncMenu = function asyncMenu() {
  return service({
    url: '/menu/getMenu',
    method: 'post'
  });
}; // @Summary 获取menu列表
// @Produce  application/json
// @Param {
//  page     int
//	pageSize int
// }
// @Router /menu/getMenuList [post]

export var getMenuList = function getMenuList(data) {
  return service({
    url: '/menu/getMenuList',
    method: 'post',
    data: data
  });
}; // @Summary 新增基础menu
// @Produce  application/json
// @Param menu Object
// @Router /menu/getMenuList [post]

export var addBaseMenu = function addBaseMenu(data) {
  return service({
    url: '/menu/addBaseMenu',
    method: 'post',
    data: data
  });
}; // @Summary 获取基础路由列表
// @Produce  application/json
// @Param 可以什么都不填 调一下即可
// @Router /menu/getBaseMenuTree [post]

export var getBaseMenuTree = function getBaseMenuTree() {
  return service({
    url: '/menu/getBaseMenuTree',
    method: 'post'
  });
}; // @Summary 添加用户menu关联关系
// @Produce  application/json
// @Param menus Object authorityId string
// @Router /menu/getMenuList [post]

export var addMenuAuthority = function addMenuAuthority(data) {
  return service({
    url: '/menu/addMenuAuthority',
    method: 'post',
    data: data
  });
}; // @Summary 获取用户menu关联关系
// @Produce  application/json
// @Param authorityId string
// @Router /menu/getMenuAuthority [post]

export var getMenuAuthority = function getMenuAuthority(data) {
  return service({
    url: '/menu/getMenuAuthority',
    method: 'post',
    data: data
  });
}; // @Summary 获取用户menu关联关系
// @Produce  application/json
// @Param ID float64
// @Router /menu/deleteBaseMenu [post]

export var deleteBaseMenu = function deleteBaseMenu(data) {
  return service({
    url: '/menu/deleteBaseMenu',
    method: 'post',
    data: data
  });
}; // @Summary 修改menu列表
// @Produce  application/json
// @Param menu Object
// @Router /menu/updateBaseMenu [post]

export var updateBaseMenu = function updateBaseMenu(data) {
  return service({
    url: '/menu/updateBaseMenu',
    method: 'post',
    data: data
  });
}; // @Tags menu
// @Summary 根据id获取菜单
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body api.GetById true "根据id获取菜单"
// @Success 200 {string} json "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /menu/getBaseMenuById [post]

export var getBaseMenuById = function getBaseMenuById(data) {
  return service({
    url: '/menu/getBaseMenuById',
    method: 'post',
    data: data
  });
};