import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";
import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import "core-js/modules/es.json.stringify.js";
import axios from 'axios'; // 引入axios

import { store } from '@/store';
import context from '@/main';
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 99999
});
var acitveAxios = 0;
var timer;

var showLoading = function showLoading() {
  acitveAxios++;

  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(function () {
    if (acitveAxios > 0) {
      context.$bus.emit('showLoading');
    }
  }, 400);
};

var closeLoading = function closeLoading() {
  acitveAxios--;

  if (acitveAxios <= 0) {
    clearTimeout(timer);
    context.$bus.emit('closeLoading');
  }
}; // http request 拦截器


service.interceptors.request.use(function (config) {
  if (!config.donNotShowLoading) {
    showLoading();
  }

  var token = store.getters['user/token'];
  var user = store.getters['user/userInfo'];
  config.data = JSON.stringify(config.data);
  config.headers = {
    'Content-Type': 'application/json',
    'x-token': token,
    'x-user-id': user.ID
  };
  return config;
}, function (error) {
  closeLoading();

  _Message({
    showClose: true,
    message: error,
    type: 'error'
  });

  return error;
}); // http response 拦截器

service.interceptors.response.use(function (response) {
  closeLoading();

  if (response.headers['new-token']) {
    store.commit('user/setToken', response.headers['new-token']);
  }

  if (response.data.code === 0 || response.headers.success === 'true') {
    return response.data;
  } else {
    _Message({
      showClose: true,
      message: response.data.msg || decodeURI(response.headers.msg),
      type: response.headers.msgtype || 'error'
    });

    if (response.data.data && response.data.data.reload) {
      store.commit('user/LoginOut');
    }

    return response.data.msg ? response.data : response;
  }
}, function (error) {
  closeLoading();

  _MessageBox.confirm("\n    <p>\u68C0\u6D4B\u5230\u63A5\u53E3\u9519\u8BEF".concat(error, "</p>\n    <p>\u9519\u8BEF\u7801500\uFF1A\u6B64\u7C7B\u9519\u8BEF\u5185\u5BB9\u5E38\u89C1\u4E8E\u540E\u53F0panic\uFF0C\u5982\u679C\u5F71\u54CD\u60A8\u6B63\u5E38\u4F7F\u7528\u53EF\u5F3A\u5236\u767B\u51FA\u6E05\u7406\u7F13\u5B58</p>\n    <p>\u9519\u8BEF\u7801404\uFF1A\u6B64\u7C7B\u9519\u8BEF\u591A\u4E3A\u63A5\u53E3\u672A\u6CE8\u518C\uFF08\u6216\u672A\u91CD\u542F\uFF09\u6216\u8005\u8BF7\u6C42\u8DEF\u5F84\uFF08\u65B9\u6CD5\uFF09\u4E0Eapi\u8DEF\u5F84\uFF08\u65B9\u6CD5\uFF09\u4E0D\u7B26</p>\n    "), '接口报错', {
    dangerouslyUseHTMLString: true,
    distinguishCancelAndClose: true,
    confirmButtonText: '清理缓存',
    cancelButtonText: '取消'
  }).then(function () {
    store.commit('user/LoginOut');
  });

  return error;
});
export default service;